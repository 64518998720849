
;/*** Source: js/script.js ***/

;(function($, $window, $document, undefined){
// Site wide variables
    var $html, 
        $body, 
        $page, 
        $main,
        $header,
        $mainbanner,
        $banners, 
        $main_nav,
        $nav_left,
        $nav_right,
        $enter,
        $logo,
        $logo_affix,
        resize      = 0,
        scroll      = 0,
        scrollRatio = 0,
        scrollScale = 1,
        startWidth  = 0,
        endWidth    = 0,
        breakpoint  = 768,
        clientWidth = $window.width();
    
    $document.ready(function(){
                               
        /* Uncomment what you need */
        $html       = $('html').removeClass('no-js').addClass('domready');
        $body       = $('body');
        $page       = $('.home #page');
        $main       = $('.home #main');
        $header     = $(".home #header");
        $logo       = $('.home .logo');
        $logo_affix = $('.home .logo.affix');
        $main_nav   = $('.home .navbar-main');
        $nav_left   = $('.home .navbar-nav:first-child');
        $nav_right  = $('.home .navbar-nav:last-child');
        $banners    = $('.banner:has(>.banner)');
        $enter      = $('#enter');
        $mainbanner = $('#banner').on('click', function(){
            
            $body.animate({
                scrollTop   : $("#header").offset().top
            }, 1000);
            
        });

        
        // Scroll to page id
        $body.on('click', '[href*="#"]', function(e){
            
            if(this.host === window.location.host && this.pathname === window.location.pathname && this.hash) {
                e.preventDefault();

                $body.animate({
                    scrollTop   : $(this.hash).offset().top
                }, 1000);
            }
            
        });
        
        
        $('.article-list > .row').slick({
            //centerMode      : true,
            centerPadding   : 0,
            mobileFirst     : true,
            dots            : false,
            transformEnabled : false,
            responsive      : [
                {
                    breakpoint  : 767,
                    settings    : {
                        slidesToShow    : 2
                    }   
                },
                {
                    breakpoint  : 992,
                    settings    : {
                        slidesToShow    : 3
                    }
                },
                {
                    breakpoint  : 1200,
                    settings    : {
                        slidesToShow    : 4
                    }
                }
            ],
            zIndex          : 99,
            slide           : 'article',
            prevArrow: '<a class="left carousel-control" role="button" data-slide="prev"><i class="icon-back" aria-hidden="true"></i><span class="sr-only">Previous</span></a>',
            nextArrow: '<a class="right carousel-control" role="button" data-slide="next"><i class="icon-forward" aria-hidden="true"></i><span class="sr-only">Next</span></a>',
        });
        
        
        $('.people .article-list > .row').slick('setOption', 'responsive', [
                {
                    breakpoint  : 1200,
                    settings    : {
                        slidesToShow    : 3
                    }
                }
            ]
        ).slick('reinit');
        
        
        var $navbarToggle = $('.navbar button').click(function(){
            
            $body.toggleClass('menu-open');
            
            if($navbarToggle.data('active'))
                $navbarToggle.text('Open Menu').data('active', false);
            
            else{
                $navbarToggle.text('Close Menu').data('active', true);
                $header.css('height', 'auto');
            }
            
        });
                
        $header.css('height', $header.outerHeight(true));
                
        $logo.affix({

            offset  : {
                top     : function(){
                                    
                    if(clientWidth >= 768)
                        return $window.height()/2 - $logo.height() - 40;
                
                },
                bottom  : function(){

                    if(clientWidth >= 768)
                        return $body.outerHeight() - $header.offset().top - $logo.height() - 60;
                    
                        //return $body.outerHeight() - $window.height() - $logo.height() - 10;
                    
                }
            }


        }).animate({
            
            opacity : 1
            
        }).on('affix.bs.affix', function(){
            
            if(clientWidth >= 768){
                
                $logo.css({
                        top             : $logo.height() + 40
                });
                
                $main_nav.css({
                    top     : 60,
                    opacity : 1
                });
                
            }
            
        }).on('affix-top.bs.affix', function(){
            
            clientWidth >= 768 && $logo.css('top', '50vh');
            
        }).on('affixed-bottom.bs.affix', function(){
            
            $page.hasClass('open') || $page.addClass('open').css('height', 'auto');
            
            if(clientWidth < 768){
                                
                $main_nav.css({
                    top     : 'auto',
                    opacity : 1     
                });
                                                
            }
            
        });
        
        startWidth = $main_nav.affix({
            
            offset  : {
                top     : 0,
                bottom  : function(){
                    
                    if(clientWidth >= 768)
                        return $body.outerHeight() - $header.offset().top - $main_nav.height() - 50;
                    
                }
            }
            
        }).animate({
            opacity : 1
        }).width();
        
        endWidth = $main_nav.parent().width();
        
        // Enable Superfish on nav menus
        $.fn.superfish && $('.navbar-nav').superfish({
            speed   : 200
        });
        
        // Enable tooltips for menu items with descriptions
        if ($.fn.tooltip) {

            $(".navbar-nav a[data-description]").tooltip({
                placement: 'bottom',
                title: function () {

                    return $(this).data('description');

                }
            });

        }

        
        // Handle Banners with nested banners
        if ($banners.length) {
            
            var slider;

            $banners.each(function () {

                slider = $banners.data('slider');

                if (slider) switch (slider) {

                    case 'slick': // Slick support for banner components. As much as possible it adopts styles from BS' carousel component
                        
                        $banners.slick({
                            prevArrow: '<a class="left carousel-control" role="button" data-slide="prev"><i class="icon-back" aria-hidden="true"></i><span class="sr-only">Previous</span></a>',
                            nextArrow: '<a class="right carousel-control" role="button" data-slide="next"><i class="icon-forward" aria-hidden="true"></i><span class="sr-only">Next</span></a>',
                            //autoplay: false,
                            //autoplaySpeed: 3000,
                            centerMode: true,
                            centerPadding: '0',
                            //cssEase: 'ease',
                            dots: false,
                            dotsClass: 'carousel-indicators slick-dots',
                            //fade: true,
                            //focusOnSelect: false,
                            //initialSlide: 0,
                            //lazyLoad: 'ondemand',
                            mobileFirst: true,
                            //pauseOnHover: true,
                            //pauseOnFocus: true,
                            //pauseOnDotsHover: false,
                            //respondTo: 'window',
                            //rows: 1,
                            slide: '.banner',
                            //slidesPerRow: 1,
                            slidesToShow : 1,
                            //slidesToScroll: 1,
                            swipeToSlide: true,
                            //vertical: false,
                            //verticalSwiping: false,
                            responsive: [
                                {
                                    breakpoint  : 940 + 30,
                                    settings    : {
                                        variableWidth: true,
                                        speed: 800
                                    }
                                }
                            ],
                        }).addClass('carousel').children('.slick-list').addClass('banner-group');
                        
                        break;

                    default:
                        $banners[slider]();

                } else $.fn.slider && $banners.slider();

            });

        }
        
        
    });
    
    $window.ready(function(){
        
        $('html').removeClass('loading').addClass('loaded');
                
    }).resize(function(){
        
        if(!resize){
            
            $body.addClass('resizing');
            
            resize = window.setTimeout(function(){
                
                clientWidth = $window.width();
                
                /* Place resize handlers here */
                endWidth    = $main_nav.parent().width();
                startWidth  = endWidth/2;

                if(clientWidth >= breakpoint)
                    $header.css('height', $logo.height() + $main_nav.outerHeight(true) + 60);
                
                $main_nav.affix('checkPosition');
                $logo.affix('checkPosition');
                
                $window.trigger('scroll');
                
                $window.trigger('monk.resize', { width: $window.width(), height: $window.height() });
                $body.removeClass('resizing');
                resize = 0;
                
            }, 100);
            
        }
                
    }).scroll(function() {
                        
        scrollRatio = $window.scrollTop() / $mainbanner.height();

        if(scrollRatio < 1)
                $body.removeClass('open');
        else
                $body.addClass('open');
                

        if(scrollRatio <= 1) {
            
            $main.css({
                transform   : 'translateY(' + ((1 - scrollRatio) * 100) + 'vh)',
                opacity     : scrollRatio
            });            
            
            $enter.css('opacity', 1 - (scrollRatio * 3));
            
            if(clientWidth >= 768) {
                
                $main_nav.css('max-width', startWidth + Math.round(scrollRatio * (endWidth - startWidth)));

                $nav_left.css('padding-right', scrollRatio * 150);
                $nav_right.css('padding-left', scrollRatio * 150);
                
                scrollScale = 2 - Math.min($window.scrollTop() / ($window.height()/2 - $logo.height() - 50), 1);
                
                $logo.css('transform', 'translateY(-50%) scale3d(' + scrollScale + ',' + scrollScale + ',' + scrollScale + ')');
                
            }
            

        }else
                $main.css({
                        opacity     : 1,
                        transform   : 'none'
                });
        
    });
        
    
})(jQuery, jQuery(window), jQuery(document));

